@import '../../utils/styles/variables.scss';

.userSideNav-menu {
    width: 100%;
    .userSideNav-button-wrapper {

        a {
            color: #000;
            font-weight: 500;
        }
        .userSideNav-button {
            text-align: left;
            width: 100%;
            margin-bottom: 18px;
            border: 1px solid #c5d7e5;
            border-radius: 5px;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 10px;
        }
        .userSideNav-button-active {
            color: $orange;
        }
    }
}
