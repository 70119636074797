@import '../../utils/styles/variables.scss';

.join-wrapper {
    background-image: linear-gradient(left, $lightBlue, $lightBlue 35%, transparent 35%, transparent 100%);
    background-image: -webkit-linear-gradient(left, $lightBlue, $lightBlue 35%, transparent 35%, transparent 100%);
    background-repeat: no-repeat;
}

.join-container {
    margin-top: 55px;
    margin-bottom: 55px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    max-width: 100% !important;
    .join-column-right {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 7% !important;
    }
    .join-img {
        max-width: 350px;
        margin-right: -200px
    }
    .join-message {
        text-align: left;
        color: $navyBlue;
        font-size: 25px;
        word-wrap: break-word;
    }
    .join-message-special{
        color: $orange;
        font-weight: 800;
        font-size: 35px;
        text-align: left;
        margin-top: 10px;
    }
    .join-buttons {
        text-align: left;
    }
}


@media (max-width: 900px) {

    .join-container {
        display: block !important;
    }
    .join-img {
      width: 300px !important;
      margin-right: 0 !important;
      margin: auto;
    }
    .join-column{
        width: 100% !important;
    }
    .join-text, .join-message {
        width: 100%;
        word-break: break-word;
    }
    .join-container .join-column-right {
        flex-direction: column;
        margin-left: 0 !important;
        margin: auto;
    }
  }