@import '../../utils/styles/variables.scss';

.registerUser-column {
    text-align: center;
    margin-bottom: 55px;
    margin-top: 55px;

    &.smaller-margins {
        margin-top: 0;
        margin-bottom: 40px;
    }
}

.registerUser-text-wrapper {
    margin-bottom: 25px;
    text-align: center;
}

.registerUser-info-box {
    background: #e3f5ff;
    color: #063d71;
    border-radius: 10px;
    padding: 10px;
    margin: 0 32px;
    
    @media (max-width: 900px) {
        margin: 0;
    }

    span {
        margin-left: 10px;
    }
}

.registerUser-header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        color: #fb7422;
        font-weight: bold;
    }
}

.registerUser-header {
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 10px;
}

.registerUser-header-3 {
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
}


@media (max-width: 900px) {
    .registerUser-column {
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .registerUser-header {
        margin-bottom: 0;
    }
  }