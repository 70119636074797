@import '../../utils/styles/variables.scss';


.profileView-user {
    color: $navyBlue;
}
.profileView-title {
    text-align: left;
    font-weight: 400;
    width: 100%;

}

.profileView-span {
    font-size: 16px;
}
.profileView-title-4{
    font-weight: 400;
    text-align: left;
    font-size: 18px;
}


.rating{
    display: inline-block;
    font-size: 26px;
    margin-top: 15px;
    .rating-up {
        color: green;
        display: inline-block;
        margin-right: 10px;
    }
    .rating-down {
        color: red;
        display: inline-block;
        margin-left: 10px;
    }
}

.profileView-downleft {
    margin-bottom: 15px;
    .profileView-price-left {
        text-align: left;
        width: 49%;
        display: inline-block
    }
    .profileView-price-right {
        text-align: right;
        width: 49%;
        display: inline-block
    }
}

.userInfo {
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 50px;
    border: 1px solid #caced0;
    border-radius: 10px;
}

.profileView-more {
    margin-top: 15px;
    margin-bottom: 50px;
    text-align: left;
}


.commentsList-container {
    margin-bottom: 25px;

    .date-span{
        display: block;
        margin: auto;
        margin-bottom: 3px;
    }
}
.commentsList-header {
    background: $lightBlue;
    color: $navyBlue;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    align-items: center !important;
    .comments-span {
        font-weight: 300;
    }
}

.commentsList-header-1 {
    border-top-left-radius: 10px;
}

.commentsList-header-6 {
    border-top-right-radius: 10px;
}

.comments-element {
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center !important;
}



.comments-element {
  
    .rating {
        display: inline-block;
        font-size: 20px !important;
        margin: 0 !important;
        .rating-up {
            color: green;
            display: inline-block;
            margin: 0 !important;
        }
        .rating-down {
            color: red;
            display: inline-block;
            margin: 0 !important;
        }
    }

}

.commentsList-element-date, .commentsList-element-expired {
    text-align: left;
    margin-bottom: 25px;
}

.commentsList-comment {
    align-items: center;
}

.commentsList-comment {
    border-bottom: 1px solid #caced0;
    border-left: 1px solid #caced0;
    border-right: 1px solid #caced0;

}
.comments-element-left {
    border-right: 1px solid #caced0;
    display: flex;
    .rating, .rating-user {
        text-align: center;
        width: 40%;
        margin-left: 10%;
        margin-right: 10%;
    }

}

.comments-element-right{
    text-align: left;

}

.comment-last {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.commentsList-comments:last-of-type:after {
    display: none;
}

.commentsList-element-1 {
    border-right: 1px solid #caced0;
}

.commentsList-element-7 {
    border-left: 1px solid #caced0;
}

.comment-sort {
    display: flex;
    .comment-sort-left {
        margin-left: 15px;
        margin-right: 15px;
        color: green;
    }
    .comment-sort-right {
        color: red;
    }
}

.comment-brand {
    margin-top: 10px;
    text-align: center;
    font-weight: 600;
}

.rating-date {
    font-size: 10px;
}

.profileView-id {
    display: block;
    font-size: 16px;
}

@media (max-width: 900px) {

    .mobile-margin {
        margin-top: 10px;
    }
}