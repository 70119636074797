@import '../../utils/styles/variables.scss';

.login-link {
    text-align: right;
}

.login-buttons {
    margin-top: 15px;
    margin-bottom: 15px;
}

.login-error-message {
    color: $red;
    margin-bottom: 20px;
}