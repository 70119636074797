@import '../../utils/styles/variables.scss';

.info {
    display: block;
    margin: auto;
    text-align: center;
    position: absolute;
    bottom: -20px;
    font-size: 14px;
    font-weight: 400;
}

.hours {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    display: inline-block;
    position: relative;
    font-size: 26px;
    font-weight: 600;
}

.time-wrapper {
    text-align: center;
}

.time-wrapper-list {
    text-align: center;
    .hours {
        font-size: 14px;
    }
}
.info-span-left {
    right: 0;
}

.info-span-right{
    left: 0;
}