.transactionView-column {
    margin-bottom: 25px;

}
.transactionView-title {
    text-align: left;
    font-weight: 400;
    width: 100%;

}

.header-customer { font-size: 20px;}

.transactionView-span {
    text-align: left;
}

.transactionView-zakres {
    text-align: left;
}

.transactionView-price-right{
    .pricebox-price {
        padding-right: 15px;
    }
    .pricebox-price .pricebox-span {
        position: absolute;
        top: 0px;
        font-size: 18px;
        right: 0px;
    }
    .pricebox-top::after {
        margin-top: 5px;
    }
    .pricebox-price {
        font-weight: 500;
    }
    .accent {
        font-weight: 500;
    }
}

.transactionView-downleft {
    margin-bottom: 10px;
    .transactionView-price-left {
        text-align: left;
        width: 49%;
        display: inline-block
    }
    .transactionView-price-right {
        text-align: left;
        width: 49%;
        display: inline-block
    }
}

.transaction-rating{
    display: inline-block;
    font-size: 20px;
    margin-left: 10px;
    .rating-up {
        color: green;
        display: inline-block;
        margin-right: 5px;
    }
    .rating-down {
        color: red;
        display: inline-block;
        margin-left: 5px;
    }
}


.transactionView-title {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.transactionView-description {
    text-align: left;
}

.transaction-last {
    margin-bottom: 25px;
}

.trans-wrapper {
    display: flex;
    align-items: center;
}


.trans-adres {
    display: flex;
}
@media (max-width: 900px) {

   
}