@import '../../utils/styles/variables.scss';

.grid-info {
    text-align: left;
    margin-bottom: 20px;
}

.link {
    color: $navyBlue;
    font-weight: 500;
}