@import '../../utils/styles/variables.scss';

.footer-container {
    max-width: 95% !important;
    background-color: $lightBlue;
    padding-top: 25px;
    padding-bottom: 25px;
    font-weight: 500;
    color: $navyBlue;
    a { 
        color: $navyBlue;
    }
    .footer-column {
        text-align: left;
    }
    .footer-logo {
        max-width: 180px;
        margin-bottom: 15px;
    }
    .footer-header {
        text-align: left;
        color: $navyBlue;
        font-weight: 500;
    }
    .footer-header-special {
        font-weight: 700;
    }

    .footer-content {
        text-align: left;
        .footer-element {
            display: block;
            text-align: left;
            min-height: 19px;
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 14px;
        }
        .footer-element-smaller {
            font-size: 10px;
        }
        .footer-icon {
            display: inline-block;
            margin-left: 6px;
            margin-right: 6px;
            background-color: white;
            color: $orange;
            padding: 15px;
            border-radius: 50px;
            width: 15px;
            height: 15px;
            text-align: center;
            position: relative;
            svg {
                position: absolute;
                top: 7px;
                left: 10px;
            }
            a {
                color: $orange;
            }
        }
        .footer-text-icon {
            margin-right: 15px;
        }
        .footer-link {
            font-weight: 600;
            color: $navyBlue;
            padding-left: 4px;
        }
    }

    .footer-text-accent {
        color: $navyBlue;
    }
}
.footer-copyright {
    margin-top: 15px;
    margin-bottom: 15px;
    color: $navyBlue;
    font-weight: 500;
    font-size: 14px;
}

.footer-socials {
    display: none;
}

@media (max-width: 900px) {
    .footer-column, .footer-socials {
        width: 100% !important;
        max-width: 100% !important;
    }

    .footer-socials {
        display: block;
    }
    .footer-socials-mobile{
        display: none !important;
    }
    .footer-text-icon {
        margin-right: 3px !important;
    }
}