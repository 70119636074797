@import '../../utils/styles/variables.scss';

.pros-wrapper {
    
}

.pros-container {
    .pros-header-wrapper {
        display: flex;
        align-content: center;
    }
    .pros-header {
        font-weight: 400;
        font-size: 35px;
    }
    .pros-text-special {
        font-weight: 600;
        color: $orange;
        margin-right: 5px;
        margin-left: 5px;
        display: inline-block;
    }
    .pros-img-wrapper {
        height: 175px;
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .pros-text-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 20px;
        min-height: 100px;
        padding: 10px;
        word-break: break-word;
    }
}
.pros-column {
    padding-top: 30px;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
}

.pros-column-left {
    align-items: center;
}

.pros-column-inner {
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    background-color: $white;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0px -5px 10px#efebeb, 
    -10px 8px 15px #efebeb, 10px 8px 15px #efebeb;
}

@media (max-width: 1200px) {

    .pros-column-inner {min-height: 375px;}
}


@media (min-width: 2000px) {

    .pros-column-inner {min-height: 400px;}
}