.registerServiceViewForm-column {
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-top: 25px;
    .radio-button-wrapper {
        margin-left: 5px;
        margin-bottom: 15px;
    }
    .radioButton-text {
        font-size: 12px;
    }
}

.registerServiceViewForm-buttons {
    position: relative;
    margin-top: 10px;

    .registerServiceViewForm-back-button-wrapper {
        position: absolute;
        left: 15px;
        font-weight: bold;
        cursor: default;

        span {
            margin-left: 3px;
        }
    }

    .registerServiceViewForm-button-wrapper {
        position: absolute;
        right: 0;
        top: -15px;
    }
}

.registerServiceViewForm-input {
    margin-bottom: 10px;
}

.registerService-header-3 {
    font-weight: 400;
    margin-bottom: 25px !important;
}

.registerServiceViewForm-column-info {
    margin-top: 20px;
}

.errors-row {
    margin-top: 30px;
    text-align: left;
    color: red;
}

@media (max-width: 900px) {
    .registerServiceViewForm-column {
        padding: 5px;
        .radioButton-icon-wrapper {
            margin-bottom: 5px !important;
        }
    }
    .registerServiceViewForm-column-info {
        margin-top: 15px;
    }
    .registerService-header {
        margin-top: 10px;
        margin-bottom: 10px;
    }
  }

  .registerViewForm {
    margin-left: -25px;
    font-size: 10px;
    margin-top: -15px;
    display: block;
}

.registerViewForm-msg {
    font-size: 10px;
    display: block;
}