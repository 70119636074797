@import '../../utils/styles/variables.scss';

.checkbox-wrapper {
  display: flex;
  flex: wrap;
  align-items: flex-start;

  .checkbox-container {
    max-width: 7%;
    display: inline-block;
  }
  .checkbox-text {
    text-align: left;
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    display: inline-block;
  }
  .checkbox-label {
    display: flex;
  }
  .error-star {
    color: red;
  }
}
.error-message {
  color: red;
  text-align: left;
  margin-top: -15px;
  margin-left: 30px;
  font-size: 12px;
}