@import '../../utils/styles/variables.scss';

.loginRegisterView-column {
    text-align: center;
    margin-bottom: 55px;
}

.loginRegisterView-column-inner {

}

.loginRegisterView-header {
    font-size: 45px;
    font-weight: 400;
}

.loginRegisterView-header-3 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 50;
    margin-top: 0;
    text-align: left;
}

.login-header-3 {
    margin-bottom: 10px !important;
}

.login-header-info-wrapper {
    position: relative;
    width: 100%;
}

.login-header-info {
    width: 100%;
    text-align: center;
    position: absolute;
    top: -40px;
    font-size: 14px;
    left: 0;
}

.loginRegisterViewViewForm-information {
    font-weight: 600;
    text-align: left;
    font-size: 12px;
    .loginRegisterViewViewForm-information-accent {
        color: $orange;
        margin-right: 5px;
    }
}

.loginRegisterViewViewForm-information-accent {
    color: $orange;
    font-size: 14px;
    text-align: left;
}

.registerUserViewForm-row {
    margin-top: 50px;
}

.header-accent {
    color: $orange;
    margin-right: 5px;
}

.header-accent-dark {
    color: $navyBlue;
    margin-right: 5px;
}

.login-header-big {
    margin-bottom: 10px;
}

.register-text {
    font-size: 15px;
    margin-top: 10px;

    a {
        margin-left: 5px;
        font-weight: bold;
    }
}