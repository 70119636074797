.registerUserViewForm-container {
    padding: 25px;
}

.registerUserViewForm-buttons {
    position: relative;
    margin-top: 10px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;

    .registerUserViewForm-button-wrapper {}
}

.registerUserViewForm-input {
    margin-bottom: 10px;
}

.registerViewForm {
    margin-left: -25px;
    font-size: 10px;
    margin-top: -15px;
    display: block;
}

.registerViewForm-msg {
    font-size: 10px;
    margin-top: -5px;
    display: block;
}

.registerUser-header-3 {
    margin-bottom: 12px;
}

.data-admin-info {
    text-align: left;
}

.email_is_used {
    color: red;
}