@import '../../utils/styles/variables.scss';

.offerList-container {
    margin-bottom: 25px;

    .date-span{
        display: block;
        margin: auto;
        margin-bottom: 3px;
    }
}
.offerList-header {
    background: $lightBlue;
    color: $navyBlue;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    align-items: center !important;
    .offer-span {
        font-weight: 300;
    }
}

.offerList-header-1 {
    border-top-left-radius: 10px;
}

.offerList-header-6 {
    border-top-right-radius: 10px;
}

.offerList-element {
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center !important;
}

.isOpened {
    display: none !important;
}

.isOpened-true {
    display: block;
    margin-bottom: 50px;

    .offerList-element-1 {
        border-left: 1px solid #caced0;
        border-bottom: 1px solid #caced0;
        border-radius: 0 0 0 10px;
    }
    
    .offerList-element-7 {
        border-right: 1px solid #caced0;
        border-bottom: 1px solid #caced0;
        border-radius: 0 0 10px 0;
    }
}

.offerList-element {
  
    .rating {
        display: inline-block;
        font-size: 10px !important;
        margin: 0 !important;
        margin-left: 5px !important;
        .rating-up {
            color: green;
            display: inline-block;
            margin-right: 2px !important;
        }
        .rating-down {
            color: red;
            display: inline-block;
            margin-left: 2px !important;
        }
        .svg-inline--fa {
            margin-right: 2px;
        }
    }

    .pricebox-price {
        padding-right: 15px;
    }
    .pricebox-price .pricebox-span {
        position: absolute;
        top: 0px;
        font-size: 18px;
        right: 0px;
    }
    .pricebox-top::after {
        margin-top: 5px;
    }
    .pricebox-price {
        font-weight: 500;
    }
    .accent {
        font-weight: 500;
    }
}

.offerList-element-date, .offerList-element-expired {
    text-align: left;
    margin-bottom: 25px;
}

.offerList-offer {
    align-items: center;
}

.offerList-offer:after {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    content: "";
    height: 1px;
    background: #caced0;
}

.offer-last {
    
    .offerList-element {
        border-bottom: 1px solid #caced0;
    }
    .offerList-element-1 {
        border-bottom-left-radius: 10px;
    }
    .offerList-element-7 {
        border-bottom-right-radius: 10px;
    }
}
.offer-last:after {
    display: none;
}

.commentsList-comments:last-of-type:after {
    display: none;
}

.offerList-offer:last-of-type:after {
    display: none;
}

.offerList-element-1 {
    border-left: 1px solid #caced0;
}

.offerList-element-7 {
    border-right: 1px solid #caced0;
}

.contact-title-1 {
    font-weight: 600;
    display: inline-block;
}

.offer-description {
    margin-top: 25px;
    width: 60%;
    margin-left: 20%;
    display: block;
    text-align: left;
}

.contact-info {
    margin-top: 25px;
    width: 60%;
    margin-left: 20%;
    text-align: left;
}

.offer-phone {
    margin-top: 10px; 
    margin-bottom: 10px;
}
.offer-button-wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
}

.offerList-left {
    display: inline-block;
    width: 49%;
}
.offerList-right {
    display: inline-block;
    width: 50%;
    text-align: center;
}


.offerList-element-expired {
    display: flex;
}

.offer-phone {
    .input-wrapper {
        margin-top: 7px;
    }
}

.offer-form-error {
    color: red;
    font-size: 16px;
}

.offer-cancelled {
    color: grey !important;
    a, .rating-up, .rating-down {
        color: grey !important;
    }
    .offerList-element {
        background: #f3eeee;
    }
}