.double-form {
    margin-top: 15px;
    .input-wrapper {
        margin-bottom: 0 !important;
    }
}

.double-form-right{
    margin-left: 15px;
}

.service-dash-form{
    display: flex;
    align-items: start;
    .ui.fluid.dropdown {
        padding: 10px 25px;
    }
}
.dash-h3-span {
    font-size: 12px;
    margin-left: 10px;
    font-weight: 500;
}

.registerServiceViewForm-input {
    text-align: left;
}