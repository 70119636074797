.green {
    color: green;
}
.red {
    color: red;
}

.modal-rating {
    font-size: 30px !important;
    display: block !important;
    margin: auto !important;
    width: 15%;
    text-align: center;
    padding-bottom: 10px;
    .rating-up{
        margin-right: 15px;
        padding: 7px;
    }
    .rating-down {
        margin-left: 15px;
        padding:7px;
    }
    .rate-chosen {
        border: 1px solid #063d71;
        border-radius: 50px;
    }
}

.modal-text {
    color: black;
}

@media (max-width: 900px) {

   .modal-rating {
       width: 30%;
   }
}