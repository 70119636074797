@import '../../utils/styles/variables.scss';


.register-column {
    max-height: 420px;
    text-align: center;
    margin-bottom: 55px;
    margin-top: 55px;
}

.register-img-wrapper {
    max-width: 250px;
    max-height: 175px;
    margin: auto;
    margin-top: 25px;
}
.register-text-wrapper {
    margin-top: 35px;
    margin-bottom: 25px;
    text-align: center;
}

.register-column-inner {
    margin: 15px;
    padding: 5px;
    padding-top: 15px;
    height: 90%;
    padding-bottom: 15px;
    box-shadow: 0px -5px 10px#efebeb, 
    -10px 8px 15px #efebeb, 10px 8px 15px #efebeb;
}

.register-header {
    font-weight: 400;
}

.register-header-3 {
    font-weight: 400;
}


@media (max-width: 900px) {
    .register-column {
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .register-column-inner {
        margin: 0;
        height: auto;
    }
  }