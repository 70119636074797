@import '../../utils/styles/variables.scss';

.contactView-form {
    margin-bottom: 50px;
}

.contactView-column {
    text-align: center;
    margin-bottom: 55px;
}

.contactView-header {
}

.contactView-header-3 {
    margin-bottom: 50;
    margin-top: 0;
}

.contactView-header-3 {
    margin-bottom: 50px;
}

.contactView-header-info-wrapper {
    position: relative;
    width: 100%;
}

.contactView-header-info {
    width: 100%;
    text-align: center;
    position: absolute;
    top: -40px;
    font-size: 14px;
    left: 0;
}

.contactViewForm-information {
    font-weight: 600;
    text-align: left;
    font-size: 12px;
    .contactViewForm-information-accent {
        color: $orange;
        margin-right: 5px;
    }
}

.contactViewForm-information-accent {
    color: $orange;
    font-size: 14px;
    text-align: left;
}

.contactView-row {
    margin-top: 50px;
}



.header-accent {
    color: $orange;
    margin-right: 5px;
}

.header-accent-dark {
    color: $navyBlue;
    margin-right: 5px;
}

.contactView-header-big {
    margin-bottom: 15px !important;
    font-size: 45px;
    font-weight: 400;

}
.contactView-header-3 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 25px !important;
}

.contactView-buttons {
    margin-bottom: 45px;
    margin-top: 15px;
}

.contact-error-message {
    color: $red;
    margin-bottom: 20px;
}