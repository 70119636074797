.orderView-column {
    margin-bottom: 25px;

}
.orderView-title {
    text-align: left;
    font-weight: 400;
    margin-top: 25px !important;
    margin-bottom: 15px !important;
}


.orderView-span {
    text-align: left;
}

.orderView-zakres {
    text-align: left;
}

.orderView-description {
    margin-top: 10px;
}