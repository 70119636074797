@import '../../utils/styles/variables.scss';

.register-info-wrapper {
    background-color: $lightBlue;
    padding: 10px;
    text-align: left;
    color: $navyBlue;
}

.register-info-special {
    font-weight: 600;
}