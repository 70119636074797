@import '../../utils/styles/variables.scss';

.instruction-container {
    margin-top: 100px;
    margin-bottom: 100px;
    .instruction-header {
        font-size: 35px;
        text-align: center;
        width: 100%;
        font-weight: 500;
    }
    .instruction-text {
        color: $navyBlue;
        font-size: 24px;
        font-weight: 400;
        .instruction-text-special {
            display: block;
            color: $orange;
            font-weight: 500;
        }
    }
    .instruction-step {
        position: relative;
    }
    .instruction-step.step-1:after,
    .instruction-step.step-2:after,
    .instruction-step.step-3:after {
        position: absolute;
        content: ''; 
        display: block;
        height: 1px;
        width: 75px;
        background-color: $navyBlue;
        top: 50%;
        right: -5%;
    }
}

@media (max-width: 900px) {
    .instruction-step.step-1:after,
    .instruction-step.step-2:after,
    .instruction-step.step-3:after{
        display: none !important;
    }

}