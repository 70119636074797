@import '../../utils/styles/variables.scss';
.navUser {
  border-radius: 10px;
  background: $lightBlue;
  color: $navyBlue;
  margin-right: 10px;
  padding: 10px;
}
.navbar-buttons-2 {
  position: absolute;
    right: 25px;
    margin-top: 30px;
    margin-bottom: 15px;
}
.nav-logout {
  color: $navyBlue;
  cursor: pointer;
}
.navbar-container {
  text-align: left;
  display: flex;
  background: white;
  overflow-x: hidden;
  height:100px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  -webkit-transform: translate3d(0,0,0);
  z-index: 666;
  top: 0;
  .navbar-logo {
    display: flex;
    margin: 10px 15px;
  }
  .nav {
    display: flex;
    margin: 10px 15px;
  }
  .navbar-list {
    list-style-type: none;
  }
  .navbar-list-element {
    color: black;
    display: inline-block;
    margin: 10px 15px;
    text-align: left;
  }
  .navbar-buttons {
    position: absolute;
    right: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.navbar-mobile-buttons {
  display: none;
  margin-right: 5px;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: $navyBlue;
  margin: 6px 0;
  transition: 0.4s;
}

.navbar-buttons-change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.navbar-buttons-change .bar2 {opacity: 0;}

.navbar-buttons-change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.navbar-mobile {
  display: none !important;
}

.navbar-container-mobile {
  text-align: left;
  display: flex;
  background: white;
  overflow-x: hidden;
  height: 95px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  -webkit-transform: translate3d(0,0,0);
  z-index: 666;
  top: 0;
  .navbar-logo {
    display: flex;
    margin: 10px 15px;
  }
  .nav {
    display: flex;
    margin: 10px 15px;
  }
  .navbar-list {
    list-style-type: none;
  }
  .navbar-list-element {
    color: black;
    display: inline-block;
    margin: 10px 15px;
    text-align: left;
  }
  .navbar-buttons {
    position: absolute;
    right: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 900px) {
  .navbar-container {
    display: none;
  }
  .navbar-container-mobile {
    width: 100%;
    height: 95px;
  }
  .navbar-mobile {
    display: block !important;
  }
  .nav-list {
      width: 100%;
      background: white;
      z-index: 200;
      margin: 0 !important;
      justify-content: center;
      // height: 350px;
      height: 0px;
      overflow: hidden;
      .navbar-list {
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        text-align: center;
      }
      .navbar-list-element {
        text-align: center;
        font-size: 26px;
        height: 50px;
        width: 100%
      }
  }
  .nav-list {
    display: none;
  }
  .nav-list-change {
    display: block;
  }
  .nav-change {
    height: 200px;
    display: block;
    transition:             height 500ms ease;
    -moz-transition:    height 500ms ease;
    -ms-transition:     height 500ms ease;
    -o-transition:      height 500ms ease;
    -webkit-transition: height 500ms ease;
  }
  .navbar-buttons {
    display: flex;
    align-items: center;
    margin-top: 20px !important;
  }
  .navbar-mobile-buttons {
    display: inline-block;
  }
  .navbar-buttons-2 {
    right: 35px !important;
  }
}