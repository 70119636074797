@import '../../utils/styles/variables.scss';

.orderDetails-wrapper {
    .title {
        font-weight: 600;
    }
    .orderDetails-header {
        width: 100%;
        background: $navyBlue;
        color: $white;
        padding: 15px;
        border-top-left-radius: 10px; 
        border-top-right-radius: 10px; 
        text-align: left;
    }
}