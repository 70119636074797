@import '../../utils/styles/variables.scss';

.login-header {
    font-weight: 400;
}

.login-container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.login-info {
    font-size: 14px;
    margin-top: 25px;
}

.login-info-special {
    color: #0050a3;
    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
}

.login-header-3 {
    margin-top: 50px;
    font-weight: 500;
}