@import '../../utils/styles/variables.scss';

.orderCheckup-wrapper {
    padding-top: 25px;

    background-image: linear-gradient(right, $lightBlue, $lightBlue 35%, transparent 35%, transparent 100%);
    background-image: -webkit-linear-gradient(right, $lightBlue, $lightBlue 35%, transparent 35%, transparent 100%);
    background-repeat: no-repeat;
}

.orderCheckup-container {
    text-align: left;
    .orderCheckup-left-wrapper {
        display: flex;
        align-items: center;
        flex: wrap;
    }
    .orderCheckup-text-container {
        font-weight: 400;
        .orderCheckup-header {
            font-weight: 400;
        }
        .orderCheckup-header-3, .orderCheckup-header-4 {
            font-weight: 400;
            margin-bottom: 10px;
        }
        .orderCheckup-header-subtitle {
            margin-bottom: 10px;
        }
        .orderCheckup-text-special {
            font-weight: 600;
            color: $navyBlue;
        }
        .orderCheckup-text-accent {
            font-weight: 600;
            color: $orange;
            margin-right: 7px;
        }
    }
}

@media (max-width: 600px) {
    .orderCheckup-column {
        text-align: center;
        margin-top: 25px;
    }
    .orderCheckup-img {
        max-width: 300px;
        max-height: 320px;
        margin: auto;
        text-align: center;
    }
}