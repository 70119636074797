@import '../../utils/styles/variables.scss';

.radioButton-wrapper {
    position: relative;
    text-align: left;

    .radioButton {
        background-color: $white;
        border-color: $navyBlue;
        border: 1px solid $navyBlue;
        color: $navyBlue;
        border-radius: 5px;
        padding: 10px;
        font-size: 16px;
        display: inline-block;
        box-sizing:border-box;
        margin-right: 10px;
    }

    .radioButton-icon {
        position: absolute;
        right: -5%;
        bottom: 37%;
        color: $navyBlue;
    }
    .radioButton-text {
        display: inline-block;
        font-size: 16px;
    }

    .radioButton-icon-wrapper {
        display: block;
        margin-bottom: 45px;
        max-width: 50px;
        max-height: 50px;
    }
    .radioButton-label {
        display: inline-block;
    }
}

.radioButton-disabled {
    color: grey;
    filter: grayscale(100%);
}
