@import '../../utils/styles/variables.scss';

.register-info-wrapper {
    background-color: $lightBlue;
    padding: 10px;
    text-align: left;
    color: $navyBlue;
}

.register-info-special {
    font-weight: 600;
}
.register-info-list {
    list-style-type: none;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px
  
}

.register-info-icon {
    color: green;
    margin-right: 7px;
}