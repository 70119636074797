@import '../../utils/styles/variables.scss';

.createOffer-column {
    text-align: left;
    margin-bottom: 55px;
}

.strong {
    font-weight: 500;
}

.pricebox {
    border: 1px solid $navyBlue;
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    .input-wrapper {
        width: 65%;
        margin: auto;
    }
    .input {
        border: 1px solid #caced0 !important;
    }
}

.pricebox-top {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px;
}

.pricebox-price {
    color: $navyBlue;
    position: relative;
    .pricebox-span {
        position: absolute;
        top: 11px;
        font-size: 18px;
        right: 16px;
    }
}

.pricebox-top::after {
    content: "";
    display: block;
    width: 80%;
    margin: auto;
    height: 1px;
    background-color: #caced0;
}

.pricebox-left {
    align-self: center;
}

.pricebox-right {
    text-align: right;
}

.createOfferViewForm-button-wrapper {
    margin-top: 25px;
}
.createOffer-top {
    border: 1px solid #caced0;
    border-radius: 10px;
    padding: 30px;
    width: 80%;
    margin-left: 10% !important;
    margin-bottom: 50px;
}

.createOfferViewForm-column {
    text-align: left;
    margin-bottom: 15px;
}

.createOfferViewForm-column-float {
    border-left: 1px solid #caced0;
    
}

.dropdown-wrapp {
    width: 100px;
    margin: auto;
    margin-top: 15px;
}

.dropdown-wrapp-1 {
    width: 60%;
    margin-left: 39%;
}

.pricebox-bot {
    align-items: center !important;
}

.createOfferViewForm-column-small {
    text-align: left;
    max-height: 40px;
}

.createOfferViewForm-info {
    font-size: 14px;
    margin-top: -10px;
    .accent {
        color: $orange;
    }
}

.createOffer-header {
    font-weight: 500;
    text-align: left;
    color: $orange;
}

.createOffer-header-3 {
    font-weight: 400;
    margin-bottom: 25px;
    margin-top: 0;
    text-align: left;
}
.createOffer-header-4 {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 15px !important;
}

.createOffer-info {
    margin-top: -15px;
    text-align: left;
}
.accent {
    color: $orange;
}

.createOfferViewForm-information {
    font-weight: 600;
    text-align: left;
    font-size: 12px;
    .createOfferViewForm-information-accent {
        color: $orange;
        margin-right: 5px;
    }
}

.createOfferViewForm-information-accent {
    color: $orange;
    font-size: 14px;
    text-align: left;
}

.registerUserViewForm-row {
    margin-top: 50px;
}

.createOffer-container {
    margin-bottom: 50px;
}

.createOfferViewForm-radio-wrapper {
    display: inline-block;
}
.createOfferViewForm-input-text {
    display: inline-block;
}

.createOfferViewForm-accent {
    color: $orange;
}

.createOfferViewForm-link {
    color: $navyBlue;
}

.createOfferViewForm-buttons {
    position: relative;
    margin-bottom: 50px;
}

.createOfferViewForm-button-wrapper {
    position: absolute;
    right: 0;
}

@media (max-width: 900px) {
    .createOfferViewForm-column {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
    }
  }