@import '../../utils/styles/variables.scss';

.textarea-wrapper {
    position: relative;

    .textarea {
        width: 100%;
        min-width: 100%;
        min-height: 125px;
        background-color: $white;
        border-color: $navyBlue;
        border: 1px solid $navyBlue;
        color: $navyBlue;
        border-radius: 5px;
        padding: 10px;
        font-size: 14px;
        display: inline-block;
        box-sizing:border-box;
        margin-bottom: 15px;
    }

    .textarea-label-red {
      color: red;
    }

    .textarea-border-red {
      border-bottom-color: red;
      border-bottom-width: 2px;
    }

    .textarea-error {
      color: red;
      text-align: left;
      font-size: 12px;
      margin-top: -20px;
    }

    .textarea::-webkit-textarea-placeholder { /* Edge */
      color: $navyBlue;
    }
      
    .textarea:-ms-textarea-placeholder { /* Internet Explorer 10-11 */
      color: $navyBlue;
    }

    .input-floating-label {
      position: absolute;
      pointer-events: none;
      left: 10px;
      top: 10px;
      transition: 0.2s ease all;
      line-height: initial;
    }

    .input-floating-label-true{
      top: -7px;
      bottom: 10px;
      left: 10px;
      height: 12px;
      padding-left: 3px;
      font-size: 11px;
      opacity: 1;
      background: white;
      padding-right: 3px;

    }
      
    .textarea::placeholder {
      color: $navyBlue;
    }

    .textarea-icon {
        position: absolute;
        right: -5%;
        bottom: 37%;
        color: $navyBlue;
    }
}