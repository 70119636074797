@import '../../utils/styles/variables.scss';

.userOrdersList-button {
    display: block;
}

.active {
    color: green;
}

.ongoing {
    color: $orange;
}

.finished {
    color: grey;
}

.carName {
    display: block;
    margin: auto;
}

.allorders-time {
    display: block;
}

.allorders-date {
    font-size: 12px;
}