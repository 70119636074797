@import '../../utils/styles/variables.scss';

.registerService-column {
    text-align: center;
    margin-bottom: 55px;
}

.registerService-header {
    font-size: 45px;
    font-weight: 400;
}

.registerService-header-3 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
}


.registerServiceViewForm-information {
    font-weight: 600;
    text-align: left;
    font-size: 12px;
    .registerServiceViewForm-information-accent {
        color: $orange;
        margin-right: 5px;
    }
}

.registerServiceViewForm-information-accent {
    color: $orange;
    font-size: 14px;
    text-align: left;
}

.registerUserViewForm-row {
    margin-top: 50px;
}

.dropdown-error-message {
    color: red;
    margin-top: -10px;
    font-size: 12px;
    text-align: left;
}

.dropdown-error-message-no-margin {
    color: red;
    font-size: 12px;
    text-align: left;
}

.dropdown-error {
    border-bottom: 2px solid red !important;
}

@media (max-width: 900px) {
    .registerService-column {
        margin-top: 10px;
        margin-bottom: 10px;

    }
}