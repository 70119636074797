@import '../../utils/styles/variables.scss';

.button {
    min-width: 125px;
    max-width: 300px;
    background-color: $white;
    border-color: $navyBlue;
    border: 1px solid $navyBlue;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    text-transform: uppercase;
    margin: 15px 10px;
    display: inline-block;
    text-align: center;
}

.button-dark {
    background-color: $navyBlue;
    color: $veryLightBlue;
}

.button-disabled {
    background-color: $peachy;
    color: $veryLightBlue;
}

.button-dark:hover, .button-light:hover {
    background-color: #0050a3;
    border-color: #0050a3;
    color: $veryLightBlue;
}

.button-orange {
    background-color: $orange;
    color: $veryLightBlue;
    border-color: $orange;
}

.button-orange:hover {
    background-color: #ff8826;
    border-color: #ff8826;
}

.button-icon {
    margin-right: 10px;
}
.button-block {
    display: block;
    margin-top: 5px;
}

.button-center {
    margin: auto;
}
