@import '../../utils/styles/variables.scss';

.createOrder-column {
    text-align: left;
    margin-bottom: 55px;
}

.createOrderViewForm-column {
    text-align: left;
    margin-bottom: 15px;
}

.createOrderViewForm-column-small {
    text-align: left;
    max-height: 40px;
}

.createOrderViewForm-info {
    font-size: 14px;
    margin-top: -10px;
    .accent {
        color: $orange;
    }
}

.createOrder-header {
    font-weight: 400;
}

.createOrder-header-3 {
    font-weight: 400;
    margin-bottom: 25px !important;
    margin-top: 0;
    text-align: left;
}

.additional-info-header {
    color: #2283fb;
    text-align: left;
    padding: 0 15px;
    margin-bottom: 30px;
    cursor: pointer;
    width: fit-content;

    & > span:nth-child(1) {
        margin-right: 10px;
    }
}


.createOrderViewForm-information {
    font-weight: 600;
    text-align: left;
    font-size: 12px;
    .createOrderViewForm-information-accent {
        color: $orange;
        margin-right: 5px;
    }
}

.createOrderViewForm-information-accent {
    color: $orange;
    font-size: 14px;
    text-align: left;
}

.registerUserViewForm-row {
    margin-top: 50px;
}

.createOrder-container {
    margin-bottom: 50px;
}

.createOrderViewForm-radio-wrapper {
    display: inline-block;
}
.createOrderViewForm-input-text {
    display: inline-block;
}

.createOrderViewForm-accent {
    color: $orange;
}

.createOrderViewForm-link {
    color: $navyBlue;
}

.createOrderViewForm-buttons {
    position: relative;
    margin-bottom: 50px;
}

.createOrderViewForm-button-wrapper {
    display: flex;
    justify-content: center;
}
.createOrder-input {
    margin-bottom: 15px;
}

.createOrderViewForm-input-country {
    margin-bottom: 15px;
}
.textarea-container {
    width: 100%;
    padding: 0 15px;

    .createOrderViewForm-info {
        text-align: left;
    }
}
.register-info-wrapper {
    margin: 0 15px;
}
@media (max-width: 900px) {
    .createOrderViewForm-column-small {
        // min-height: 80px !important;
    }
    .createOrder-container {
        margin-bottom: 5px !important;
    }
    .createOrderViewForm-column {
        display: flex;
        align-items: flex-end;
    }

    .createOrderViewForm-column-small {
        margin-bottom: 7px;
    }
    .createOrderViewForm-input-text {
        width: 88%;
    }

    .createOrderViewForm-button-wrapper {
        position: relative; 
        display: block;
        margin: auto;
        text-align: center;
    }
    .createOrderViewForm-buttons {
        margin: auto;
    }
    .createOrder-header-3 {
        margin-bottom: 10px !important;
    }
    .createOrder-header {
        margin-bottom: 10px !important;
    }
  }

.createOrderRow {
    margin-bottom: 10px;
}

.geocoder-wrapper {
    position: relative;

    .search-icon {
        position: absolute;
        top: 6px;
        right: 12px;
        color: rgba(0, 0, 0, 0.5);
    }

    .input-error {
        font-size: 12px;
        text-align: left;
        color: red;
    }

    &.geocoder-error {
        input {
            border-bottom: 2px solid red;
        }
    }
}

.geocoder-info-text {
    margin-top: 4px;
    text-align: left;
}

.mapbox-wrapper {
    margin-top: 16px;
    margin-bottom: 8px;
    height: 0;
    overflow: hidden;

    &.mapbox-visible {
        height: 300px;
    }
}

.react-geocoder {
    input {
        width: 100%;
        border: 1px solid #063d71;
        color: #063d71;
        border-radius: 5px;
        height: 38px;
        padding: .68em 40px .68em 1em;
    }
}

.react-geocoder-results	{
    border: 1px solid #063d71;
    border-radius: 5px;
    overflow: hidden;
}

.react-geocoder-item {
    padding: 4px 1em;
    text-align: left;
    border-top: 1px solid #ddd;

    &:hover {
        cursor: pointer;
        background: #eee;
    }
}

.mapbox-marker {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #063d71;
    position: relative;
    top: -6px;
    left: -6px;
}