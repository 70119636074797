@import '../../utils/styles/variables.scss';

.orderInfo-wrapper {
        width: 100%;
        text-align: left;
    .orderInfo-inner {
        padding: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .orderInfo-top {
        border-left: 1px solid #caced0;
        border-right: 1px solid #caced0;
        width: 100%;
        position: relative;
    }
    .orderInfo-top:after {
        content: "";
        background: #caced0;
        position: absolute;
        bottom: 0;
        left: 10%;
        height: 1px;
        width: 80%;
    }
    .orderInfo-bottom {
        width: 100%;
        border-bottom: 1px solid #caced0;
        border-left: 1px solid #caced0;
        border-right: 1px solid #caced0;
        border-bottom-left-radius: 10px; 
        border-bottom-right-radius: 10px; 
    }
    .orderInfo-title {
        font-weight: 600;
        display: block;
        margin-bottom: 5px;
    }
}