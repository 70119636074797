body {
  line-height: 1.8em !important;
}

a, a:hover, a:visited {
  text-decoration: none;
  opacity: 1 !important;
}

a {
  color: #2283fb;
}

input:focus {
  outline: none;
}

.ui.disabled.dropdown, .ui.dropdown .menu>.disabled.item {
  opacity: 0.85 !important;
}

.ui.search input::placeholder {
  color: #063d71 !important;
}

input[type="button"]
{
    width:120px;
    height:60px;
    margin-left:35px;
    display:block;
    background-color:gray;
    color:white;
    border: none;
    outline:none;
}

.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
  color: rgb(74 72 72 / 95%) !important;
}

.ui.search.dropdown>input.search, .ui.selection.dropdown, .ui.input>input {
  border-color: #063d71 !important;
    border: 1px solid #063d71;
    border-radius: 5px !important;
}

.dash-h2, .dash-h3 {
margin-bottom: 25px !important;
text-align: left;

}

.dashAcc-wrapper {
margin-bottom: 75px;
}
h1, h2, h3, h4, h5 {
  word-wrap: normal !important;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table tbody td {
  border-bottom: 1px solid #ccd6e2;
  padding: 10px;
  vertical-align: middle;
}

table tbody {
  border: 1px solid #ccd6e2;
}

table thead th {
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

table thead tr {
  background-color: #063d71;
  color: white;
  font-weight: 500;
  padding: 10px;
  text-transform: uppercase;
}

table thead th:first-of-type{
  border-top-left-radius: 10px; 
}

table thead th:last-of-type{
  border-top-right-radius: 10px; 
}

table tr:hover td {
  background-color: #f4fdff;
  }
  .userOrdersList-button {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #fb7422;
    margin-top: 3px;
    margin-bottom: 3px;
  }
 .components-main-component {
   min-height: 57vh;
 }
 .ui.search, .ui.input {
   width: 100% !important;
 }

 .ui.input {

  margin-bottom: 10px !important;
 }
 .ui.search .prompt {
   border-radius: 5px !important;
 }
@media (max-width: 900px) {
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 26px;
    font-weight: 400;
  }
  .mobile-disable {
    display: none;
  }
}
.App {
  text-align: center;
  position: relative;
}

@media (max-width: 900px) { 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.pointer-element {
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
