@import '../../utils/styles/variables.scss';

.dualButton {
    min-width: 120px;
    max-width: 300px;
    background-color: $white;
    border-color: $navyBlue;
    border: 1px solid $navyBlue;
    padding: 10px;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    text-align: center;
}

.dualButton-dark {
    background-color: $navyBlue;
    color: $veryLightBlue;
}

.dualButton-dark:hover, .dualButton-light:hover {
    background-color: #0050a3;
    border-color: #0050a3;
    color: $veryLightBlue;
}

.dualButton-orange {
    background-color: $orange;
    color: $veryLightBlue;
    border-color: $orange;
}

.dualButton-orange:hover {
    background-color: #ff8826;
    border-color: #ff8826;
}

.dualButton-icon {
    margin-right: 10px;
}

.dualButton-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.dualButton-right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

@media (max-width: 900px) {
    .dualButton-hide {
        min-width: 35px;
        max-width: 35px;
        text-align: center;
    }
    .dualButton-icon {
        margin-right: 0;
        margin: auto;
    }
    .dualButton-text-hide {
        display: none !important;
    }
  
  }

