@import '../../utils/styles/variables.scss';

.video-container {
    font-size: 30px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}
.videobox-container {
    .videobox-wrapper {
        width: 450px;
        height: 300px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 15px 15px 15px 15px #fcf1ee;
        .videobox {
            width: 400px;
            height: 250px;
            background-color: grey;
        }
    }
}
.video-header {
    width: 100%;
    text-align: center;
    font-size: 35px;
}
.video-col {
    display: flex;
    .video-text {
        display: flex;
        align-items: center;
        .video-special {
            font-weight: 600;
            color: $navyBlue;
        }
        .video-info {
            display: block;
            font-weight: 400;
            font-size: 20px;
            text-align: left;
            span {
                display: block;
            }
        }
    }
}