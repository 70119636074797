@import '../../utils/styles/variables.scss';

.pagination-controls {
    margin-top: 15px;
    margin-bottom: 15px;
}

.pagination-pages {
    width: 70%;
    display: inline-block;
    text-align: right;
}

.pagination-offset {
    width: 29%; 
    display: inline-block;
    text-align: left;
    font-weight: 400;
    span {
        margin-left: 5px;
    }
}
.offset-option-active {
    font-weight: 500;
    color: $orange;
}

.pagination-controls__button {
    color: black;
    display: inline-block;
    font-weight: 400;
    padding: 5px 10px;
}

.pagination-controls__button--active {
    background-color: $orange;
    color: white;
    font-weight: 500;
    border-radius: 5px;
}