@import '../../utils/styles/variables.scss';


.findOrder-column {
    max-height: 420px;
    text-align: center;
    margin-bottom: 55px;
    margin-top: 55px;
}

.findOrder-img-wrapper {
    max-width: 250px;
    max-height: 175px;
    margin: auto;
    margin-top: 25px;
}
.findOrder-text-wrapper {
    margin-top: 35px;
    margin-bottom: 25px;
    text-align: center;
}

.findOrder-column-inner {
    margin: 15px;
    padding: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0px -5px 10px#efebeb, 
    -10px 8px 15px #efebeb, 10px 8px 15px #efebeb;
}

.findOrder-header {
    font-weight: 400;
}

.findOrder-header-3, .findOrder-header-4 {
    font-weight: 400;
}


@media (max-width: 900px) {
    .findOrder-column {
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .findOrder-column-inner {
        margin: 0;
    }
  }