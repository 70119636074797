@import '../../../utils/styles/variables.scss';

.orderCheckupForm-container {
    display: flex;
    flex: wrap;
    align-items: flex-start;
    .orderCheckupForm-form {
        display: flex;
        align-items: flex-start;
    }
    .orderCheckupForm-input {
        width: 255px;
        margin-right: 30px;
    }
    .orderCheckupForm-buttons {
        margin-top: -5px;
        .orderCheckupForm-info {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            margin-top: -5px;
        }
    }
}

@media (max-width: 900px) {
    .orderCheckupForm-container{
        align-items: center !important;
    }
    .orderCheckupForm-input {
        display: block;
        margin: auto;
        margin-right: auto !important;
    }
    .orderCheckupForm-buttons {
        display: block;
        margin: auto !important;
    }
    .orderCheckupForm-form {
        margin: auto;
        flex-direction: column;
        align-items: center !important;
    }
}